import React, { useState } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const App = () => {
  const [groups, setGroups] = useState([
    {
      data: [],
      filter: {
        date: new Date(),
        fromETD: new Date().setHours(15, 0, 0, 0),
        toETD: new Date().setHours(23, 59, 0, 0),
      },
      label: "Group 1",
    },
    {
      data: [],
      filter: {
        date: new Date(),
        fromETD: new Date().setHours(0, 0, 0, 0),
        toETD: new Date().setHours(14, 59, 0, 0),
      },
      label: "Group 2",
    },
    {
      data: [],
      filter: {
        date: new Date(),
        fromETD: new Date().setHours(0, 0, 0, 0),
        toETD: new Date().setHours(23, 59, 0, 0),
      },
      label: "Group 3",
    },
  ]);

  // Categorize the flights based on flight number
  const categorizeFlight = (flightNo) => {
    if (flightNo >= 900 && flightNo <= 997) return "ic";
    if (flightNo === 999 || String(flightNo).includes("999")) return "icBuffer";
    if (flightNo >= 1200 && flightNo <= 1299) return "domestic";
    if (flightNo === 7033) return "ic";
    if (flightNo >= 7000 && flightNo <= 7999) return "charter";
    if (String(flightNo).startsWith("RYR")) return "ryanAir";  // RyanAir flights
    return "sh";
  };

  // Get style based on the category of the flight for PDF
  const getRowStyle = (flightNo) => {
    const category = categorizeFlight(flightNo);

    switch (category) {
      case "ic":
        return { backgroundColor: [255, 255, 0] }; // Yellow (IC)
      case "icBuffer":
        return { backgroundColor: [255, 0, 0], textColor: [255, 255, 255] }; // Red (IC Buffer)
      case "charter":
        return { backgroundColor: [212, 239, 223], textColor: [0, 0, 0] }; // Light green (Charter)
      case "ryanAir":
        return { backgroundColor: [204, 153, 102], textColor: [0, 0, 0] }; // Light Brown (RyanAir)
      case "domestic":
        return { backgroundColor: [173, 216, 230], textColor: [0, 0, 0] }; // Light Blue (Domestic)
      default:
        return {};
    }
  };

  // Parse ETD (Estimated Time of Departure)
  const parseETD = (etd) => {
    if (typeof etd === "number") {
      const totalMinutes = Math.round(etd * 24 * 60);
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
    }
    if (typeof etd === "string" && etd.includes(":")) {
      return etd;
    }
    return "00:00";
  };

  // Handle file upload and parse Excel data
  const handleFileUpload = (event, groupIndex) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const binaryData = e.target.result;
      const workbook = XLSX.read(binaryData, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      let sheetData = XLSX.utils.sheet_to_json(worksheet);

      sheetData = sheetData.map((row) => ({
        Date: row["Date"],
        "Flight No": row["Flight No"],
        ETD: parseETD(row["ETD"]),
      }));

      setGroups((prevGroups) =>
        prevGroups.map((group, index) =>
          index === groupIndex ? { ...group, data: sheetData } : group
        )
      );
    };

    reader.readAsBinaryString(file);
  };

  // Filter the data based on the filter settings
  const filterData = (data, filter) => {
    const groupDate = formatDate(filter.date);
    const fromETDMinutes = parseTimeToMinutes(new Date(filter.fromETD).toTimeString().slice(0, 5));
    const toETDMinutes = parseTimeToMinutes(new Date(filter.toETD).toTimeString().slice(0, 5));

    return data.filter((row) => {
      const rowDate = row.Date;
      const rowETDMinutes = parseTimeToMinutes(row.ETD);

      return (
        rowDate === groupDate &&
        rowETDMinutes >= fromETDMinutes &&
        rowETDMinutes <= toETDMinutes
      );
    });
  };

  // Remove duplicate entries based on Date, Flight No, and ETD
  const removeDuplicates = (data) => {
    const uniqueRecords = {};
    return data.filter((row) => {
      const key = `${row.Date}-${row["Flight No"]}-${row.ETD}`;
      if (!uniqueRecords[key]) {
        uniqueRecords[key] = true;
        return true;
      }
      return false;
    });
  };

  // Parse time string to minutes for comparison
  const parseTimeToMinutes = (timeString) => {
    if (typeof timeString === "string") {
      const [hours, minutes] = timeString.split(":").map(Number);
      return hours * 60 + minutes;
    }
    return 0;
  };

  // Format the date as dd/MM/yyyy
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Update the filter settings
  const updateFilter = (index, key, value) => {
    setGroups((prevGroups) =>
      prevGroups.map((group, i) =>
        i === index ? { ...group, filter: { ...group.filter, [key]: value } } : group
      )
    );
  };

  // Generate Reports for All Groups (Filtered for Group 1, Group 2, Group 3)
  const generateReports = () => {
    const generateGroupReport = (groupIndex, groupLabel) => {
      const filteredData = removeDuplicates(filterData(groups[groupIndex].data, groups[groupIndex].filter));
      
      const doc = new jsPDF();
      doc.setFontSize(14);
      const pageWidth = doc.internal.pageSize.getWidth();
      const textWidth = doc.getTextWidth(`${groupLabel} Flights`);
      doc.text(`${groupLabel} Flights`, (pageWidth - textWidth) / 2, 20);
      
      doc.setFontSize(12);
      doc.text(`${groupLabel} - Flights`, 14, 30);
      doc.autoTable({
        head: [["Date", "Flight No", "ETD"]],
        body: filteredData.map((row) => [row.Date, row["Flight No"], row.ETD]),
        startY: 40,
        styles: {
          fontSize: 10,
          cellPadding: 3,
          halign: "center",
        },
        willDrawCell: (data) => {
          const style = getRowStyle(data.row.cells[1].raw);
          if (style.backgroundColor) {
            const [r, g, b] = style.backgroundColor;
            data.cell.styles.fillColor = [r, g, b];
          }
          if (style.textColor) {
            const [r, g, b] = style.textColor;
            data.cell.styles.textColor = [r, g, b];
          }
        },
      });
      
      doc.save(`${groupLabel}_Flights.pdf`);
    };
    
    generateGroupReport(0, "Group 1");
    generateGroupReport(1, "Group 2");
    generateGroupReport(2, "Group 3");
  };

  // Generate All Reports (IC Flights for Group 1 & 2, Charter Flights for Group 1 & 2, IC Flights for Group 3)
  const generateAllReports = () => {
    // IC Flights for Group 1 and Group 2
    const generateICFlightsPDF = () => {
      const filteredGroup1Data = removeDuplicates(filterData(groups[0].data, groups[0].filter));
      const filteredGroup2Data = removeDuplicates(filterData(groups[1].data, groups[1].filter));

      const icGroup1Data = filteredGroup1Data.filter((row) => categorizeFlight(row["Flight No"]) === "ic");
      const icGroup2Data = filteredGroup2Data.filter((row) => categorizeFlight(row["Flight No"]) === "ic");

      const doc = new jsPDF();

      doc.setFontSize(14);
      const pageWidth = doc.internal.pageSize.getWidth();
      const textWidth = doc.getTextWidth("IC Flights of Group 1 and Group 2");
      doc.text("IC Flights of Group 1 and Group 2", (pageWidth - textWidth) / 2, 20);

      doc.setFontSize(12);
      doc.text("Group 1 - IC Flights", 14, 30);
      doc.autoTable({
        head: [["Date", "Flight No", "ETD"]],
        body: icGroup1Data.map((row) => [row.Date, row["Flight No"], row.ETD]),
        startY: 40,
        styles: {
          fontSize: 10,
          cellPadding: 3,
          halign: "center",
        },
        willDrawCell: (data) => {
          const style = getRowStyle(data.row.cells[1].raw);
          if (style.backgroundColor) {
            const [r, g, b] = style.backgroundColor;
            data.cell.styles.fillColor = [r, g, b];
          }
          if (style.textColor) {
            const [r, g, b] = style.textColor;
            data.cell.styles.textColor = [r, g, b];
          }
        },
      });

      doc.addPage();
      doc.text("Group 2 - IC Flights", 14, 20);
      doc.autoTable({
        head: [["Date", "Flight No", "ETD"]],
        body: icGroup2Data.map((row) => [row.Date, row["Flight No"], row.ETD]),
        startY: 30,
        styles: {
          fontSize: 10,
          cellPadding: 3,
          halign: "center",
        },
        willDrawCell: (data) => {
          const style = getRowStyle(data.row.cells[1].raw);
          if (style.backgroundColor) {
            const [r, g, b] = style.backgroundColor;
            data.cell.styles.fillColor = [r, g, b];
          }
          if (style.textColor) {
            const [r, g, b] = style.textColor;
            data.cell.styles.textColor = [r, g, b];
          }
        },
      });

      doc.save("IC_Flights_Group1_and_Group2.pdf");
    };

    // Charter Flights for Group 1 and Group 2
    const generateCharterFlightsPDF = () => {
      const filteredGroup1Data = removeDuplicates(filterData(groups[0].data, groups[0].filter));
      const filteredGroup2Data = removeDuplicates(filterData(groups[1].data, groups[1].filter));

      const charterGroup1Data = filteredGroup1Data.filter((row) => categorizeFlight(row["Flight No"]) === "charter");
      const charterGroup2Data = filteredGroup2Data.filter((row) => categorizeFlight(row["Flight No"]) === "charter");

      const doc = new jsPDF();

      doc.setFontSize(14);
      const pageWidth = doc.internal.pageSize.getWidth();
      const textWidth = doc.getTextWidth("Charter Flights of Group 1 and Group 2");
      doc.text("Charter Flights of Group 1 and Group 2", (pageWidth - textWidth) / 2, 20);

      doc.setFontSize(12);
      doc.text("Group 1 - Charter Flights", 14, 30);
      doc.autoTable({
        head: [["Date", "Flight No", "ETD"]],
        body: charterGroup1Data.map((row) => [row.Date, row["Flight No"], row.ETD]),
        startY: 40,
        styles: {
          fontSize: 10,
          cellPadding: 3,
          halign: "center",
        },
        willDrawCell: (data) => {
          const style = getRowStyle(data.row.cells[1].raw);
          if (style.backgroundColor) {
            const [r, g, b] = style.backgroundColor;
            data.cell.styles.fillColor = [r, g, b];
          }
          if (style.textColor) {
            const [r, g, b] = style.textColor;
            data.cell.styles.textColor = [r, g, b];
          }
        },
      });

      doc.addPage();
      doc.text("Group 2 - Charter Flights", 14, 20);
      doc.autoTable({
        head: [["Date", "Flight No", "ETD"]],
        body: charterGroup2Data.map((row) => [row.Date, row["Flight No"], row.ETD]),
        startY: 30,
        styles: {
          fontSize: 10,
          cellPadding: 3,
          halign: "center",
        },
        willDrawCell: (data) => {
          const style = getRowStyle(data.row.cells[1].raw);
          if (style.backgroundColor) {
            const [r, g, b] = style.backgroundColor;
            data.cell.styles.fillColor = [r, g, b];
          }
          if (style.textColor) {
            const [r, g, b] = style.textColor;
            data.cell.styles.textColor = [r, g, b];
          }
        },
      });

      doc.save("Charter_Flights_Group1_and_Group2.pdf");
    };

    // IC Flights for Group 3
    const generateICFlightsGroup3PDF = () => {
      const filteredGroup3Data = removeDuplicates(filterData(groups[2].data, groups[2].filter));

      const icGroup3Data = filteredGroup3Data.filter((row) => categorizeFlight(row["Flight No"]) === "ic");

      const doc = new jsPDF();

      doc.setFontSize(14);
      const pageWidth = doc.internal.pageSize.getWidth();
      const textWidth = doc.getTextWidth("IC Flights of Group 3");
      doc.text("IC Flights of Group 3", (pageWidth - textWidth) / 2, 20);

      doc.setFontSize(12);
      doc.autoTable({
        head: [["Date", "Flight No", "ETD"]],
        body: icGroup3Data.map((row) => [row.Date, row["Flight No"], row.ETD]),
        startY: 30,
        styles: {
          fontSize: 10,
          cellPadding: 3,
          halign: "center",
        },
        willDrawCell: (data) => {
          const style = getRowStyle(data.row.cells[1].raw);
          if (style.backgroundColor) {
            const [r, g, b] = style.backgroundColor;
            data.cell.styles.fillColor = [r, g, b];
          }
          if (style.textColor) {
            const [r, g, b] = style.textColor;
            data.cell.styles.textColor = [r, g, b];
          }
        },
      });

      doc.save("IC_Flights_Group3.pdf");
    };

    generateICFlightsPDF();
    generateCharterFlightsPDF();
    generateICFlightsGroup3PDF();
  };

  return (
    <div style={{ padding: "20px" }}>
      <h2>Generate All Flight Data Reports</h2>
      {groups.map((group, index) => (
        <div key={index}>
          <h3>{group.label}</h3>
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={(e) => handleFileUpload(e, index)}
          />
          <div>
            <label>Date: </label>
            <DatePicker
              selected={group.filter.date}
              onChange={(date) => updateFilter(index, "date", date)}
              dateFormat="dd/MM/yyyy"
            />
            <label> From: </label>
            <DatePicker
              selected={new Date(group.filter.fromETD)}
              onChange={(time) => updateFilter(index, "fromETD", time)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="ETD"
              dateFormat="HH:mm"
            />
            <label> To: </label>
            <DatePicker
              selected={new Date(group.filter.toETD)}
              onChange={(time) => updateFilter(index, "toETD", time)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="ETD"
              dateFormat="HH:mm"
            />
          </div>
        </div>
      ))}
      <div>
        <button onClick={generateReports}>Generate Reports for All Groups</button>
        <button onClick={generateAllReports}>Generate All Reports</button>
      </div>
    </div>
  );
};

export default App;
